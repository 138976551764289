import React, {useEffect}  from "react"
import { styled } from "@mui/material/styles"
import logoPiramide from 'components/material-dashboard-pro-react/img/icono_piramide.svg'
import logoOceanica from 'components/material-dashboard-pro-react/img/oce.svg';
import { useDialog } from 'context/DialogContext'
import { useLoading } from 'context/LoadingContext'
import { initAxiosInterceptors } from 'utils/axiosConfig'
import styles from 'styles/appStyles.js'

const LayoutPortalLazy = React.lazy(() => {  
    return insuranceCompany === 'OCEANICA' ? import('Portal/Layout/Oceanica/Layout') : import('Portal/Layout/Piramide/Layout');
}) 
const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY
const logoCompany = insuranceCompany === 'OCEANICA' ? logoOceanica : logoPiramide;

const NewDiv = styled("div")(({ theme, ownerState }) => {
    const { style } = ownerState
    return { ...styles[style] }
  })

  const NewImg = styled("img")(({ theme }) => {
    return { ...styles.logo }
  })

const LoadingLayout = () => {

    return (
        <NewDiv
            ownerState={{
                style: "container",
            }}
        >
            <NewDiv
                ownerState={{
                    style: "logoContainer",
                }}
            >
                <NewImg src={logoCompany} />
            </NewDiv>
        </NewDiv>
    )
}

const App = () => {

    const isSSR = typeof window === "undefined"
    const dialog = useDialog();
  const loading = useLoading();
  useEffect(() =>{
    if (sessionStorage.length > 0 ){
      initAxiosInterceptors(dialog, loading);
    }else{
      window.location = "/login"
    }
  },[]);

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<LoadingLayout />}>
          <LayoutPortalLazy/> 
        </React.Suspense>
      )}
    </>
  )

}


export default App
